import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  teams: any[] = [];
  leads: any;

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getTaxonomy();
  }

  getTaxonomy() {
    this._authService.getReq('team_member_taxonomys').subscribe(
      (data: any[]) => {
        this.teams = data.map((element) => {
          return {
            id: element.id,
            name: element.name,
            members: []
          }
        });
        this.getTeams();
      }
    )
  }

  getTeams() {
    this._authService.getReq('team_members?per_page=100').subscribe(
      (data: any[]) => {
        data.forEach((element) => {
          this.teams.forEach((team) => {
            if (team.id === element.acf.department) {
              team.members.push({ name: element.acf.name, department: team.name, picture: 'url(' + element.acf.picture + ')', designation: element.acf.designation, intro: element.acf.introduction });
            }
          })
        });
        this.segregateLead();
      }
    )
  }

  segregateLead() {
    this.teams.forEach((element) => {
      if (element.name === 'Lead') {
        this.leads = element;
        this.teams.splice(this.teams.indexOf(element), 1);
      }
    });
  }

}
