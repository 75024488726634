import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  validatingForm: FormGroup;

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.validatingForm = new FormGroup({
      contactFormModalName: new FormControl('', Validators.required),
      contactFormModalEmail: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      contactFormModalSubject: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', Validators.required)
    });
  }

  get contactFormModalName() {
    return this.validatingForm.get('contactFormModalName');
  }

  get contactFormModalEmail() {
    return this.validatingForm.get('contactFormModalEmail');
  }

  get contactFormModalSubject() {
    return this.validatingForm.get('contactFormModalSubject');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  send() {
    if (this.validatingForm.status === 'VALID') {
      let body = {
        name: this.validatingForm.value.contactFormModalName,
        email: 'banglanatak@gmail.com',
        emailTitle: 'Enquiry from Banglanatk Dot Com',
        emailBody: '<h3>' + this.validatingForm.value.contactFormModalName + ' just made an enquiry <h3>' + '<p>Name: ' + this.validatingForm.value.contactFormModalName + '</p><p>Email: ' + this.validatingForm.value.contactFormModalEmail + '</p><p> Subject: ' + this.validatingForm.value.contactFormModalSubject + '</p><p> Message: ' + this.validatingForm.value.contactFormModalMessage + '</p>'
      };
      this._authService.sendMail(body).subscribe(
        (data: any) => {
        }
      );
    }
  }

}
