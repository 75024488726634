import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-whats-new-detail',
  templateUrl: './whats-new-detail.component.html',
  styleUrls: ['./whats-new-detail.component.scss']
})
export class WhatsNewDetailComponent implements OnInit {

  whatsNew: { title: string, pictures: any[], content: any, youtubes: any[], links: any[] };

  constructor(
    private route: ActivatedRoute,
    private _authService: AuthorizationService
  ) {
    route.params.subscribe((data) => {
      this.getWhatsNew(data.id);
    })
  }

  ngOnInit() {
  }

  getWhatsNew(input: number) {
    this._authService.getReq('what_s_news/' + input).subscribe(
      (data: any) => {
        this.whatsNew = {
          title: data.acf.title,
          pictures: [data.acf.picture_1, data.acf.picture_2, data.acf.picture_3, data.acf.picture_4].filter((element) => element),
          content: this._authService.sanatizer.bypassSecurityTrustHtml(data.acf.content),
          youtubes: [data.acf.youtube_link_1, data.acf.youtube_link_2].filter((element) => element).map(yt => this._authService.sanatizeUrl(yt)),
          links: [data.acf.webiste_link_1, data.acf.webiste_link_2].filter((element) => element.url).map(link => { return { link: link.url, title: link.title } })
        };
      }
    )
  }

}
