import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FilterCategoryService } from "src/app/services/filterCategory/filter-category.service";
import { AuthorizationService } from "src/app/services/authorization/authorization.service";
import { Subscription } from "rxjs";
import { SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-vertical-with-stories",
  templateUrl: "./vertical-with-stories.component.html",
  styleUrls: ["./vertical-with-stories.component.scss"],
})
export class VerticalWithStoriesComponent implements OnInit, OnDestroy {
  vertical: string;
  title1: string;
  title2: string;
  titleText: string;
  storyCategories: any;
  substories: any[];
  verticalSubscription: Subscription;
  isArtForLife: boolean;
  embedlinks: SafeResourceUrl[];
  constructor(
    private route: ActivatedRoute,
    public categoryService: FilterCategoryService,
    private _authService: AuthorizationService
  ) {
    this.embedlinks = [
      "oQVBLDKOqd112w",
      "eQWBPC2C4immUw",
      "8gWBGFhiqlDmZw",
      "rgXBXxjVOWEHFw",
      "2wWhiA_khzAvpw",
      "twUBeELO1jFemw",
      "qQUhGSu2_iR1fA",
      "oQXB0ggTs0eutw",
      "wgWhWYEU-H_prw",
      "YAUxHbfg_k0gNA",
      "ewXxxyq4sezQew",
      "1QXhmksCMSHqpA",
      "rAVxYXl3CHiYow",
      "PQUxONV4ui7iMw",
      "dQVx1xL9etTmUw",
      "HAUhYTKEkONaLg",
      "GwWRMLuOLSz37Q",
      "6wURs_CpTpbeGg",
      "bgUxrTu7bg_6eg",
      "ZAWBnNOlbmMb8A",
      "OAXxfh8OlPdw-A"
    ].map((link) =>
      this._authService.sanatizeUrl(
        `https://embed.culturalspot.org/embedv2/story/${link}`
      )
    );
  }

  ngOnInit() {
    this.route.params.subscribe((path: any) => {
      if (!this.categoryService.verticals.length) {
        this.verticalSubscription = this.categoryService.theVerticals.subscribe(
          (theVerticals) => {
            this.handleVertical(path, theVerticals);
          }
        );
      } else {
        this.handleVertical(path, this.categoryService.verticals);
      }
    });
  }

  ngOnDestroy() {
    if (this.verticalSubscription) {
      this.verticalSubscription.unsubscribe();
    }
  }

  handleVertical(data: any, theVerticals: any) {
    this.vertical = data.vertical;
    let arr: string[] = data.vertical.split(" ");
    this.title1 = arr[0];
    arr.shift();
    this.title2 = arr.join(" ");
    this.isArtForLife = this.vertical == "Art For Life";
    theVerticals.forEach((element) => {
      if (element.name === data.vertical) {
        this.titleText = element.description;
        this.categoryService.stories.forEach((story) => {
          if (story.parentId === element.id) {
            this.storyCategories = story;
            this.storyCategories.stories.forEach((substory) => {
              substory = this.getStories(substory);
            });
          }
        });
      }
    });
  }

  getStories(input: any) {
    input.substoryIds = [];
    this._authService
      .getReq("substories?categories=" + input.id + "&per_page=100")
      .subscribe((data: any[]) => {
        input.substoryIds = data.map((element) => {
          return {
            id: element.id,
            title: element.acf.title,
            intro: element.acf.introduction,
            cover_pic: element.acf.cover_picture,
          };
        });
        return input;
      });
  }

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow:
      "<div class='nav-btn next-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer'></div>",
    prevArrow:
      "<div class='nav-btn prev-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);'></div>",
    dots: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  slickInit(e) {}
  breakpoint(e) {}
  afterChange(e) {}
  beforeChange(e) {}
}
