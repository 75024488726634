import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { TheModalComponent } from '../../utilComps/the-modal/the-modal.component';

@Component({
  selector: 'app-accredation-certification-partners',
  templateUrl: './accredation-certification-partners.component.html',
  styleUrls: ['./accredation-certification-partners.component.scss']
})
export class AccredationCertificationPartnersComponent implements OnInit {

  @ViewChild(TheModalComponent, {static: false}) theModal: TheModalComponent;

  certifications: any[] = [];
  accreditations: any[] = [];
  partners: any[];
  whoWeWorkWiths: any[];
  selectedMedia: any;

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getCertifications();
    this.getPartners();
    this.getWhoWeWorkWiths();
  }

  getCertifications() {
    this._authService.getReq('certifications?per_page=100').subscribe(
      (data: any[]) => {
        this.certifications = data.map((element) => {
          if (element.acf.category === "Certification") {
            return {
              title: element.acf.title,
              organisation: element.acf.presenting_organisation,
              time: element.acf.time,
              picture: element.acf.picture,
              link: element.acf.link
            }
          } else {
            return false;
          }
        }).filter((element) => element);
        this.accreditations = data.map((element) => {
          if (element.acf.category === "Accreditation") {
            return {
              title: element.acf.title,
              organisation: element.acf.presenting_organisation,
              time: element.acf.time,
              picture: element.acf.picture,
              link: element.acf.link
            }
          } else {
            return false;
          }
        }).filter((element) => element);
      }
    )
  }

  getPartners() {
    this._authService.getReq('partners?per_page=100').subscribe(
      (data: any[]) => {
        this.partners = data.map((element) => {
          return {
            logo: element.acf.logo,
            title: element.acf.title,
            detail: element.acf.details
          }
        });
      }
    )
  }

  getWhoWeWorkWiths() {
    this._authService.getReq('who_we_work_withs?per_page=100').subscribe(
      (data: any[]) => {
        this.whoWeWorkWiths = data.map((element) => {
          return {
            title: element.acf.title
          }
        })
      }
    )
  }

  triggerModal(input: any, type: string) {
    if (type === 'certification') {
      this.selectedMedia = { title: input.title, picture: input.picture };
      this.theModal.openModal();
    } else if (type === 'partner') {
      this.selectedMedia = { title: input.title, picture: input.logo, content: input.detail };
      this.theModal.openModal();
    }
  }

}
