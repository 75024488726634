import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { TheModalComponent } from '../../utilComps/the-modal/the-modal.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  @ViewChild(TheModalComponent, { static: false }) theModal: TheModalComponent;

  events: any[] = [];

  selectedEvent: any;

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getEvents();
  }

  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);'></div>",
    "dots": false,
    "infinite": false,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 3, slidesToScroll: 1, infinite: false, dots: false }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: false
        }
      }, {
        breakpoint: 600, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }
    ]
  };
  slickInit(e) {
  }
  breakpoint(e) {
  }
  afterChange(e) {
  }
  beforeChange(e) {
  }

  getEvents() {
    this._authService.getReq('events?per_page=100').subscribe(
      (data: any[]) => {
        this.events = data.map((singleData) => {
          if ((new Date(singleData.acf.date)) > new Date) {
            return {
              title: singleData.acf.title,
              introLocation: singleData.acf.introlocation,
              location: singleData.acf.location,
              introduction: singleData.acf.introduction,
              cover_picture: singleData.acf.cover_picture,
              picture: singleData.acf.picture,
              time: singleData.acf.time_frame,
              date: new Date(singleData.acf.date),
              details: singleData.acf.details,
              category: singleData.acf.category
            }
          } else {
            return false;
          }
        }).filter((event) => event);
      }
    )
  }

  triggerModal(input: any) {
    this.selectedEvent = input;
    this.theModal.openModal();
  }

}
