import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  base_url = 'https://cms.banglanatak.com/wp-json/wp/v2';
  mail_url = 'https://mailer.bncmusical.co.in/sendmail';

  constructor(
    private http: HttpClient,
    public sanatizer: DomSanitizer
  ) { }

  getReq(relativeUrl: String) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get(encodeURI(this.base_url + '/' + relativeUrl), httpOptions);
  }

  sanatizeUrl(url: string) {
    let returnUrl: SafeResourceUrl;
    if (url.length) {
      returnUrl = this.sanatizer.bypassSecurityTrustResourceUrl(url);
      return returnUrl;
    }
  }

  sendMail(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.mail_url, body, httpOptions);
  }

}
