import { Component, OnInit } from '@angular/core';
import { FilterCategoryService } from 'src/app/services/filterCategory/filter-category.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(
    public categoryService: FilterCategoryService
  ) {
    categoryService.filter();
  }

  ngOnInit() {
  }

}
