import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-area-of-work',
  templateUrl: './area-of-work.component.html',
  styleUrls: ['./area-of-work.component.scss']
})
export class AreaOfWorkComponent implements OnInit {

  dataSource: any;
  events: any;
  stateName: any;
  tableData: any;
  mapData: any[];
  filteredMapData: any[];
  map = [{ id: "005", tooltext: "Bihar", value: "1.5" }];

  selectedMap: string;
  worldMapData: any[];

  constructor(
    private _authService: AuthorizationService
  ) {
    this.getAreasOfWork();
    this.getWorldMap();
  }

  ngOnInit() {
    this.selectedMap = 'India';
    this.worldMapData = [];
  }

  getAreasOfWork() {
    this._authService.getReq('map_tables?per_page=100').subscribe(
      (data: any) => {
        this.handleMapTable(data);
      }
    )
  }

  handleMapTable(input: any[]) {
    let data: any[] = [];
    input.forEach((element) => {
      let index = parseInt(element.acf.state.value, 10);
      if (!data[index]) {
        data[index] = {
          id: element.acf.state.value,
          tooltext: element.acf.state.label,
          value: element.acf.extent_level,
          tables: [{
            client: element.acf.client,
            sector: element.acf.sector,
            time: element.acf.time_frame,
            project: element.acf.project
          }]
        }
      } else {
        data[index].tables.push({
          client: element.acf.client,
          sector: element.acf.sector,
          time: element.acf.time_frame,
          project: element.acf.project
        })
      }
    });
    this.mapData = data;
    this.filteredMapData = this.mapData.filter((element) => element);
    this.createDataSource();
  }

  createDataSource() {
    var current = this;
    this.dataSource = {
      chart: {
        caption: "Extent of our Reach",
        subcaption: " 2000-2022",
        numbersuffix: "%",
        includevalueinlabels: "false",
        theme: "fusion",
        showmarkerlabels: "1",
        connectorthickness: "1",
        entityfillhovercolor: "#E9F9F6",
        showlegend: "1",
        legendposition: "bottom"
      },
      // Aesthetics; ranges synced with the slider
      colorrange: {
        minvalue: "0",
        maxvalue: "3.0",
        code: "#D5FFF6",
        gradient: "0",
        color: [
          {
            minvalue: "0.5",
            maxvalue: "1.0",
            color: "#82D8C9",
            label: "Low"
          },
          {
            minvalue: "1.0",
            maxvalue: "2.0",
            color: "#29B6A3",
            label: "Medium"
          },
          {
            minvalue: "2.0",
            maxvalue: "3.0",
            color: "#5DC080",
            label: "High"
          }
        ]
      },
      // Source data as JSON --> id represents countries of world.
      data: this.filteredMapData
    };

    this.events = {
      "entityRollover": function (evt, data) {
      },
      "entityRollout": function (evt, data) {
      },
      "entityClick": function (evt, data) {
        current.stateName = data.label;
        current.stateSelected(data);
      },
    }
  }

  stateSelected(input: any) {
    let index = parseInt(input.id, 10);
    if (this.mapData[index]) {
      this.tableData = this.mapData[index].tables;
    } else {
      this.tableData = [];
    }
  }

  getWorldMap() {
    this._authService.getReq('tags').subscribe(
      (tags: any[]) => {
        tags.forEach((country: any, index: number) => {
          this.worldMapData.push({ countryName: country.name, table: [] });
          this._authService.getReq('world_map_tables?tags=' + country.id).subscribe(
            (tableEntries: any[]) => {
              tableEntries.forEach((entry: any) => {
                this.worldMapData[index].table.push({
                  client: entry.acf.client,
                  sector: entry.acf.sector,
                  time: entry.acf.time_frame,
                  project: entry.acf.project
                });
              });
            }
          )
        });
      }
    )
  }

}
