import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md'

@Component({
  selector: 'app-the-modal',
  templateUrl: './the-modal.component.html',
  styleUrls: ['./the-modal.component.scss']
})
export class TheModalComponent implements OnInit, OnChanges {

  @ViewChild('eventsModal', { static: false }) eventsModal: ModalDirective;
  @ViewChild('mediaModal', { static: false }) mediaModal: ModalDirective;
  @Input('type') type: string;
  @Input('data') data: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  openModal() {
    setTimeout(() => {
      if (this.type === 'event' && this.data) {
        this.eventsModal.show();
      } else if (this.type === 'media' && this.data) {
        this.mediaModal.show();
      }
    }, 50);
  }


}
