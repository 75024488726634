import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { TheModalComponent } from '../../utilComps/the-modal/the-modal.component';

@Component({
  selector: 'app-media-full',
  templateUrl: './media-full.component.html',
  styleUrls: ['./media-full.component.scss']
})
export class MediaFullComponent implements OnInit {

  @ViewChild(TheModalComponent, {static: false}) theModal: TheModalComponent;

  p: number = 1;
  papers: any[] = [];
  selectedMedia: any;
  constructor(
    private _authService: AuthorizationService
  ) {
    this.getMedias();
  }

  ngOnInit() {
  }

  triggerModal(input: any) {
    this.selectedMedia = input;
    this.theModal.openModal();
  }


  getMedias() {
    this._authService.getReq('medias?per_page=100').subscribe(
      (data: any[]) => {
        this.papers = data.map((media) => {
          if (media.acf.category === 'Newspaper') {
            return {
              title: media.acf.title,
              category: media.acf.category,
              author: media.acf.author,
              youtube: this._authService.sanatizeUrl(media.acf.youtube_link),
              picture: media.acf.media_picture
            }
          } else {
            return false;
          }
        }).filter((paper) => paper);
      }
    )
  }
}
