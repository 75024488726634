import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-whats-new',
  templateUrl: './whats-new.component.html',
  styleUrls: ['./whats-new.component.scss']
})
export class WhatsNewComponent implements OnInit {

  p: any;
  whatsNews: any[];
  myString: string = '<p style="color: red;">Hello</p>'

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getWhatsNews();
  }

  getWhatsNews() {
    this._authService.getReq('what_s_news?per_page=100').subscribe(
      (data: any[]) => {
        this.whatsNews = data.map((element) => {
          return {
            wpId: element.id,
            title: element.acf.title,
            content: this._authService.sanatizer.bypassSecurityTrustHtml(element.acf.content),
            cover: element.acf.cover_picture,
            pictures: [element.acf.picture_1, element.acf.picture_2, element.acf.picture_3, element.acf.picture_4].filter((picture) => picture)
          }
        });
      }
    )
  }

}
