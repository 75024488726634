import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MDBBootstrapModule, TooltipModule } from 'angular-bootstrap-md';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { FusionChartsModule } from 'angular-fusioncharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as FusionMaps from "fusioncharts/fusioncharts.maps";
import * as World from "fusioncharts/maps/fusioncharts.world";
import * as India from "fusioncharts/maps/fusioncharts.india";
import * as Souternasia from "fusioncharts/maps/fusioncharts.southernasia";

import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(
  FusionCharts,
  FusionMaps,
  World,
  India,
  Souternasia,
  FusionTheme
);


import { TestComponent } from './routes/test/test.component';
import { HomeComponent } from './routes/home/home.component';
import { HomeBannerComponent } from './components/homePageComps/home-banner/home-banner.component';
import { NavBarComponent } from './components/utilComps/nav-bar/nav-bar.component';
import { FooterComponent } from './components/utilComps/footer/footer.component';
import { EventsComponent } from './components/homePageComps/events/events.component';
import { TitleComponent } from './components/utilComps/title/title.component';
import { StoriesComponent } from './components/homePageComps/stories/stories.component';
import { VerticalsComponent } from './components/homePageComps/verticals/verticals.component';
import { MediasComponent } from './components/homePageComps/medias/medias.component';
import { WhoWeAreComponent } from './routes/who-we-are/who-we-are.component';
import { TeamsComponent } from './components/whoWeAreComps/teams/teams.component';
import { AreaOfWorkComponent } from './components/whoWeAreComps/area-of-work/area-of-work.component';
import { WhatWeDoComponent } from './routes/what-we-do/what-we-do.component';
import { AccredationCertificationPartnersComponent } from './components/whoWeAreComps/accredation-certification-partners/accredation-certification-partners.component';
import { VerticalsFullComponent } from './components/whatWeDoComps/verticals-full/verticals-full.component';
import { WorkWithUsComponent } from './routes/work-with-us/work-with-us.component';
import { OpeningsComponent } from './components/workWithUsComps/openings/openings.component';
import { TestimonialsComponent } from './components/workWithUsComps/testimonials/testimonials.component';
import { DocumentsListComponent } from './components/documentComps/documents-list/documents-list.component';
import { DocumentsComponent } from './routes/documents/documents.component';
import { MediaComponent } from './routes/media/media.component';
import { MediaFullComponent } from './components/media/media-full/media-full.component';
import { WhatsNewComponent } from './components/media/whats-new/whats-new.component';
import { TheModalComponent } from './components/utilComps/the-modal/the-modal.component';
import { StoryDetailComponent } from './routes/story-detail/story-detail.component';
import { VerticalDetailComponent } from './routes/vertical-detail/vertical-detail.component';
import { WhatsNewDetailComponent } from './routes/whats-new-detail/whats-new-detail.component';
import { VerticalWithStoriesComponent } from './components/whatWeDoComps/vertical-with-stories/vertical-with-stories.component';
import { SubstoryCardComponent } from './components/whatWeDoComps/substory-card/substory-card.component';
import { ContactUsComponent } from './components/workWithUsComps/contact-us/contact-us.component';
import { WhatsNewRouteComponent } from './routes/whats-new-route/whats-new-route.component';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { OfficesComponent } from './components/whoWeAreComps/offices/offices.component';
import { SocialHandlesComponent } from './components/utilComps/social-handles/social-handles.component';
import { MediaYoutubeComponent } from './components/media/media-youtube/media-youtube.component';
declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pan': { direction: Hammer.DIRECTION_All },
    'swipe': { direction: Hammer.DIRECTION_VERTICAL },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_HORIZONTAL
            }]
          ]
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    HomeComponent,
    HomeBannerComponent,
    NavBarComponent,
    FooterComponent,
    EventsComponent,
    TitleComponent,
    StoriesComponent,
    VerticalsComponent,
    MediasComponent,
    WhoWeAreComponent,
    TeamsComponent,
    AreaOfWorkComponent,
    WhatWeDoComponent,
    AccredationCertificationPartnersComponent,
    VerticalsFullComponent,
    WorkWithUsComponent,
    OpeningsComponent,
    TestimonialsComponent,
    DocumentsListComponent,
    DocumentsComponent,
    MediaComponent,
    MediaFullComponent,
    WhatsNewComponent,
    TheModalComponent,
    StoryDetailComponent,
    VerticalDetailComponent,
    WhatsNewDetailComponent,
    VerticalWithStoriesComponent,
    SubstoryCardComponent,
    ContactUsComponent,
    WhatsNewRouteComponent,
    OfficesComponent,
    SocialHandlesComponent,
    MediaYoutubeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    SlickCarouselModule,
    NgxPaginationModule,
    FusionChartsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TooltipModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
