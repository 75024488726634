import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-openings',
  templateUrl: './openings.component.html',
  styleUrls: ['./openings.component.scss']
})
export class OpeningsComponent implements OnInit {

  validatingForm: FormGroup;
  fullTimes: any[] = [];
  selectedId: string;
  selectedCategory: string;

  opening: { title: string, id: string, location: string, criteria: string }

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getOpenings();
    this.validatingForm = new FormGroup({
      contactFormModalName: new FormControl('', Validators.required),
      contactFormModalEmail: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      contactFormModalContactNo: new FormControl('', Validators.required),
      contactFormModalGender: new FormControl('', Validators.required),
      contactFormModalQualification: new FormControl('', Validators.required),
      contactFormModalExperience: new FormControl(''),
      contactFormModalTravelWillingness: new FormControl('', Validators.required),
      contactFormModalLanguageProf: new FormControl('', Validators.required),
      contactFormModalComputer: new FormControl('', Validators.required)
    });
  }

  get contactFormModalName() {
    return this.validatingForm.get('contactFormModalName');
  }

  get contactFormModalEmail() {
    return this.validatingForm.get('contactFormModalEmail');
  }

  get contactFormModalContactNo() {
    return this.validatingForm.get('contactFormModalContactNo');
  }

  get contactFormModalGender() {
    return this.validatingForm.get('contactFormModalGender');
  }

  get contactFormModalQualification() {
    return this.validatingForm.get('contactFormModalQualification');
  }

  get contactFormModalExperience() {
    return this.validatingForm.get('contactFormModalExperience');
  }

  get contactFormModalTravelWillingness() {
    return this.validatingForm.get('contactFormModalTravelWillingness');
  }

  get contactFormModalLanguageProf() {
    return this.validatingForm.get('contactFormModalLanguageProf');
  }

  get contactFormModalComputer() {
    return this.validatingForm.get('contactFormModalComputer');
  }

  getOpenings() {
    this._authService.getReq('openings?per_page=100').subscribe(
      (data: any[]) => {
        this.fullTimes = data.map((element) => {
          return {
            title: element.acf.title, id: element.acf.position_id, location: element.acf.location, criteria: element.acf.criteria
          }
        }).filter((element) => element);
      }
    )
  }

  send() {
    if (this.validatingForm.status === 'VALID') {
      let body = {
        name: this.validatingForm.value.contactFormModalName,
        email: 'banglanatak@gmail.com',
        emailTitle: 'Opening Application from Banglanatak Dot Com',
        emailBody: '<h2> ' + this.validatingForm.value.contactFormModalName + ' just applied for ' + this.selectedCategory + ' opening. </h2><h2> Opening Id: ' + this.selectedId + '</h2><p>Name: ' + this.validatingForm.value.contactFormModalName + '</p> <p> Email: ' + this.validatingForm.value.contactFormModalEmail + '</p> <p> Contact No: ' + this.validatingForm.value.contactFormModalContactNo + '</p> <p> Gender: ' + this.validatingForm.value.contactFormModalGender + '</p> <p> Qualification: ' + this.validatingForm.value.contactFormModalQualification + '</p> <p> Experience: ' + this.validatingForm.value.contactFormModalExperience + '</p> <p> Travel Willingness: ' + this.validatingForm.value.contactFormModalTravelWillingness + '</p> <p> Language Proficieny: ' + this.validatingForm.value.contactFormModalLanguageProf + '</p> <p> Computer Proficiency: ' + this.validatingForm.value.contactFormModalComputer + '</p>'
      };
      this._authService.sendMail(body).subscribe(
        (data: any) => {
        }
      );
    }
  }
}
