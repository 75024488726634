import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-media-youtube',
  templateUrl: './media-youtube.component.html',
  styleUrls: ['./media-youtube.component.scss']
})
export class MediaYoutubeComponent implements OnInit {

  q: number = 1;
  youtubes: any[];
  constructor(
    private _authService: AuthorizationService
  ) {
    this.getMedias();
  }

  ngOnInit() {
  }


  getMedias() {
    this._authService.getReq('medias?per_page=100').subscribe(
      (data: any[]) => {
        this.youtubes = data.map((media) => {
          if (media.acf.category === 'Youtube') {
            return {
              title: media.acf.title,
              category: media.acf.category,
              author: media.acf.author,
              youtube: this._authService.sanatizeUrl(media.acf.youtube_link),
              picture: media.acf.media_picture
            }
          } else {
            return false;
          }
        }).filter((paper) => paper);
      }
    )
  }

}
