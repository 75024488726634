import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-substory-card',
  templateUrl: './substory-card.component.html',
  styleUrls: ['./substory-card.component.scss']
})
export class SubstoryCardComponent implements OnInit, OnChanges {

  @Input('substoryData') substoryData: any;
  @Input('vertical') vertical: number;
  // substory: { title: string, intro: string, cover_pic: string };

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    // this.getSubstory();
  }

  // getSubstory() {
  //   this._authService.getReq('substories/' + this.substoryId).subscribe(
  //     (data: any) => {
  //       this.substory = {
  //         title: data.acf.title,
  //         intro: data.acf.introduction,
  //         cover_pic: data.acf.cover_picture
  //       }
  //     }
  //   )
  // }

}
