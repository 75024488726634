import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.scss']
})
export class OfficesComponent implements OnInit {

  offices: any[];

  constructor(
    private _authService: AuthorizationService
  ) {
    this.getOffices();
  }

  ngOnInit() {
  }

  getOffices() {
    this._authService.getReq('offices?per_page=100').subscribe(
      (data: any[]) => {
        this.offices = data.map((office: any) => {
          return {
            title: office.acf.title,
            content: office.acf.description
          }
        });
      }
    )
  }

}
