import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-stories',
  templateUrl: './stories.component.html',
  styleUrls: ['./stories.component.scss']
})
export class StoriesComponent implements OnInit {

  stories: {wpId: string, storyType: string, title: string, intro: string, coverpics: string[], content: string, youtubes: string[]}[] = [];
  constructor(
    private _authService: AuthorizationService
  ) {
    this.getStories();
  }

  ngOnInit() {
  }

  getStories() {
    this._authService.getReq('stories?per_page=100').subscribe(
      (data: any[]) => {
        this.stories = data.map((story) => {
          return {
            wpId: story.id,
            storyType: story.acf.category,
            title: story.acf.title,
            intro: story.acf.introduction,
            coverpics: [story.acf.cover_picture_1, story.acf.cover_picture_2, story.acf.cover_picture_3, story.acf.cover_picture_4 ],
            content: story.acf.content,
            youtubes: [story.acf.youtube_video_link_1, story.acf.youtube_video_link_2, story.acf.youtube_video_link_3, story.acf.youtube_video_link_4 ]
          }
        });
      }
    )
  }

}
