import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  banners: { picture: string, caption: string }[] = [];

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getBanners();
  }

  getBanners() {
    this._authService.getReq('homebanner?per_page=100').subscribe(
      (data: Array<any>) => { this.banners = data.map((banner) => {
        return {
          picture: banner.acf.banner_image,
          caption: banner.acf.banner_caption
        }
      }) }
    )
  }

}
