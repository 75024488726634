import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  links: { name: string; link: string; }[] = [
    { name: 'women4economy.net', link: 'https://women4economy.net' },
    { name: 'toureast.in', link: 'https://toureast.in' },
    { name: 'bncmusical.com', link: 'https://bncmusical.co.in/' },
    { name: 'surjahan.com', link: 'https://surjahan.com' },
    { name: 'rcchbengal.com', link: 'https://rcchbengal.com' },
    { name: 'naturallybengal.com', link: 'https://www.naturallybengal.com' },
    { name: 'sholacraft.com', link: 'https://www.sholacraft.com/' },
    { name: 'kenjakura.com', link: 'https://kenjakura.com' },
    { name: 'puruliachau.com', link: 'https://puruliachau.com' },
    { name: 'bengalpatachitra.com', link: 'https://bengalpatachitra.com' },
    { name: 'baulfakiri.com', link: 'https://baulfakiri.com' },
    { name: 'hipamsindia.org', link: 'https://hipamsindia.org/' }
  ];

  // [
  //   { name: 'bncmusical.co.in', link: 'http://bncmusical.co.in' },
  //   { name: 'toureast.in', link: 'http://toureast.in' },
  //   { name: 'naturallybengal.com', link: 'http://www.naturallybengal.com' },
  //   { name: 'sholacraft.com', link: 'http://www.sholacraft.com/' },
  //   { name: 'kenjakura.com', link: 'http://www.kenjakura.com' },
  //   { name: 'rcchbengal.com', link: 'http://rcchbengal.com' },
  //   { name: 'ruralcrafthub.com', link: 'http://ruralcrafthub.com' },
  //   { name: 'madurofbengal.com', link: 'http://madurofbengal.com' },
  //   { name: 'rajasthansafar.com', link: 'https://rajasthansafar.com/' },
  //   { name: 'hipamsindia.org', link: 'http://hipamsindia.org/' }
  // ]

  constructor() { }

  ngOnInit() {
  }

}
