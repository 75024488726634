import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-vertical-detail',
  templateUrl: './vertical-detail.component.html',
  styleUrls: ['./vertical-detail.component.scss']
})
export class VerticalDetailComponent implements OnInit {

  vertical: {category: string, title: string, pictures: string[], intro: string, content: any, youtubes: any[] };
  similarVerticals: any[];

  constructor(
    private route: ActivatedRoute,
    private _authService: AuthorizationService
  ) {
    route.params.subscribe(
      (data: any) => { this.getVertical(data.id); }
    );
  }

  ngOnInit() {
  }

  getVertical(id: number) {
    this._authService.getReq('verticals/' + id).subscribe(
      (data: any) => {
        this.vertical = {
          category: data.acf.category,
          title: data.acf.title,
          pictures: [data.acf.cover_picture_1, data.acf.cover_picture_2, data.acf.cover_picture_3, data.acf.cover_picture_4].filter((element) => element),
          intro: data.acf.introduction,
          content: this._authService.sanatizer.bypassSecurityTrustHtml(data.acf.content),
          youtubes: [this._authService.sanatizeUrl(data.acf.youtube_video_link_1), this._authService.sanatizeUrl(data.acf.youtube_video_link_2), this._authService.sanatizeUrl(data.acf.youtube_video_link_3), this._authService.sanatizeUrl(data.acf.youtube_video_link_4)].filter((element) => element)
        };
        this.getSimilarVerticals();
      }
    );
  }

  getSimilarVerticals() {
    this._authService.getReq('verticals?per_page=100').subscribe(
      (data: any[]) => {
        this.similarVerticals = data.map((element) => {
          if (element.acf.category === this.vertical.category) {
            return {
              wpId: element.id,
              storyType: element.acf.category,
              title: element.acf.title,
              intro: element.acf.introduction,
              coverpics: [element.acf.cover_picture_1, element.acf.cover_picture_2, element.acf.cover_picture_3, element.acf.cover_picture_4 ].filter((picture) => picture)
            }
          } else {
            return false;
          }
        }).filter((element) => element);
      }
    )
  }

  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);'></div>",
    "dots":true,
    "infinite": false,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 3, slidesToScroll: 1, infinite: false, dots: true }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true
        }
      }, {
        breakpoint: 600, settings: { 
          slidesToShow: 1, slidesToScroll: 1 
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1 
        } 
      } 
    ] 
  };
  slickInit(e) {
  }
  breakpoint(e) {
  }
  afterChange(e) {
  }
  beforeChange(e) {
  }
}
