import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  constructor(
    private _authService: AuthorizationService
  ) { }

  testimonials: any[];

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: '<div class=\'nav-btn next-slide\'><img src=\'../../../../assets/components/utilities/nav-icon.png\' style=\'width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer\'></div>',
    prevArrow: '<div class=\'nav-btn prev-slide\'><img src=\'../../../../assets/components/utilities/nav-icon.png\' style=\'width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);\'></div>',
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 2, slidesToScroll: 1, infinite: true, dots: true }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true
        }
      }, {
        breakpoint: 600, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }
    ]
  };

  ngOnInit() {
    this.getTestimonials();
  }

  getTestimonials() {
    this._authService.getReq('testimonials?per_page=100').subscribe(
      (data: any[]) => {
        this.testimonials = data.map((element) => {
          return {
            name: element.acf.name,
            content: element.acf.content,
            picture: element.acf.picture,
            position: element.acf.position
          };
        });
      }
    );
  }

}
