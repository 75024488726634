import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { FilterCategoryService } from 'src/app/services/filterCategory/filter-category.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-verticals',
  templateUrl: './verticals.component.html',
  styleUrls: ['./verticals.component.scss']
})
export class VerticalsComponent implements OnInit, OnDestroy {
  verticals: any[] = [];
  verticalSubscription: Subscription;
  constructor(
    private router: Router,
    private _authService: AuthorizationService,
    private _filterService: FilterCategoryService
  ) {
    if (_filterService.verticals.length) {
      this.handleVerticals(_filterService.verticals);
    } else {
      this.verticalSubscription = this._filterService.theVerticals.subscribe((x) => this.handleVerticals(x));
    }
  }

  ngOnInit() {
  }

  handleVerticals(input: any[]) {
    this.verticals = input.map((element) => {
      return {
        id: element.id,
        name: element.name,
        featuredStories: []
      }
    });
    this.getFeaturedStories();
  }

  ngOnDestroy() {
    if (this.verticalSubscription) {
      this.verticalSubscription.unsubscribe();
    }
  }

  getFeaturedStories() {
    this.verticals.forEach((element: any) => {
      this._authService.getReq('substories?categories=' + element.id + '&per_page=100').subscribe((stories: any[]) => {
        stories.forEach((story: any) => {
          if (story.acf.featured)
          element.featuredStories.push({id: story.id, title: story.acf.title, intro: story.acf.introduction, cover: story.acf.feature_picture});
        })
      })
    });
  }

  navigateToStory(vertical: string, story: string) {
    this.router.navigate(['what-we-do/' + vertical + '/' + story]);
  }
  

}
