import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faGlobe,
  faDownload,
  faNewspaper,
  faArrowAltCircleDown,
  faArrowDown,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationService } from "src/app/services/authorization/authorization.service";
import { ModalDirective } from "angular-bootstrap-md";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-social-handles",
  templateUrl: "./social-handles.component.html",
  styleUrls: ["./social-handles.component.scss"],
})
export class SocialHandlesComponent implements OnInit {
  @ViewChild("basicModal", { static: false }) basicModal: ModalDirective;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faGlobe = faGlobe;
  faDownload = faDownload;
  faTwitter = faTwitter;
  faNewspaper = faNewspaper;
  faLeftArrow = faAngleLeft;
  faRightArrow = faAngleRight;
  isButton: boolean;
  socials: any[];
  modalTitle: string;
  selectedSocials: any[] = [];

  validatingForm: FormGroup;

  constructor(private _authService: AuthorizationService) {}

  ngOnInit() {
    this.validatingForm = new FormGroup({
      contactFormModalName: new FormControl("", Validators.required),
      contactFormModalEmail: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.email])
      ),
    });
    this.getSocials();
  }

  get contactFormModalName() {
    return this.validatingForm.get("contactFormModalName");
  }

  get contactFormModalEmail() {
    return this.validatingForm.get("contactFormModalEmail");
  }

  send() {
    if (this.validatingForm.status === "VALID") {
      let body = {
        name: this.validatingForm.value.contactFormModalName,
        email: "banglanatak@gmail.com",
        emailTitle:
          "Newsletter Subsciption Request by Visitor at Banglanatk Dot Com",
        emailBody:
          "<h3>" +
          this.validatingForm.value.contactFormModalName +
          " just made a request for Subscription to BanglanatakDotCom's Newsletter. <h3>" +
          "<p>Name: " +
          this.validatingForm.value.contactFormModalName +
          "</p><p>Email: " +
          this.validatingForm.value.contactFormModalEmail +
          "</p>",
      };
      this._authService.sendMail(body).subscribe((data: any) => {});
    }
  }

  getSocials() {
    this._authService
      .getReq("socials?per_page=100")
      .subscribe((data: any[]) => {
        this.socials = data.map((element) => {
          return {
            category: element.acf.category,
            title: element.acf.title,
            link: element.acf.link,
          };
        });
      });
  }

  showSocials(input: string) {
    this.selectedSocials = [];
    this.socials.forEach((element) => {
      if (element.category === input) {
        this.selectedSocials.push(element);
      }
    });
    this.modalTitle = "Our " + input + " Handles";
    this.basicModal.show();
  }
  isButtonVisible() {
    this.isButton = !this.isButton;
  }

  @ViewChild("txt", { static: false }) txt: ElementRef;

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (this.txt.nativeElement.contains(event.target)) {
      
    } else {
      this.isButton = false;
    }
  }
}
