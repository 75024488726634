import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit {

  categoryList: any[];
  selectedDocuments: any[];

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this._authService.getReq('document_taxonomys').subscribe(
      (data: any[]) => {
        this.categoryList = data.map((element) => {
          return {
            id: element.id,
            name: element.name,
            documents: []
          }
        });
        this.getDocuments();
      }
    )
  }

  getDocuments() {
    this._authService.getReq('documents?per_page=100&page=1').subscribe(
      (data: any[]) => {
        data.forEach((element) => {
          this.categoryList.forEach((category) => {
            if (element.acf.category === category.id) {
              category.documents.push({ name: element.acf.name, file: element.acf.file })
            }
          })
        });
      }
    );
    this._authService.getReq('documents?per_page=100&page=2').subscribe(
      (data: any[]) => {
        data.forEach((element) => {
          this.categoryList.forEach((category) => {
            if (element.acf.category === category.id) {
              category.documents.push({ name: element.acf.name, file: element.acf.file })
            }
          })
        });
      }
    );
  }

  selectDocument(input: any, isMobile: boolean) {
    if (isMobile) {
      if (this.selectedDocuments === input.documents) {
        this.selectedDocuments = [];
      } else {
        this.selectedDocuments = input.documents;
      }
    } else {
      this.selectedDocuments = input.documents;
    }
  }

}
