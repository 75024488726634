import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';

@Component({
  selector: 'app-verticals-full',
  templateUrl: './verticals-full.component.html',
  styleUrls: ['./verticals-full.component.scss']
})
export class VerticalsFullComponent implements OnInit {

  c4ds: any[] = [];
  devConsultings: any[] = [];
  tourEasts: any[] = [];
  artForLifes: any[] = [];
  musiCals: any[] = [];

  vertical: {wpId: string, title: string, intro: string, coverpictures: string[], content: string, youtubes: string[]};

  constructor(
    private _authService: AuthorizationService
  ) { }

  ngOnInit() {
    this.getVerticals();
  }

  getVerticals() {
    this._authService.getReq('verticals?per_page=100').subscribe(
      (data: any[]) => {
        data.forEach((element) => {
          if (element.acf.category === "Development Consulting") {
            this.handleVerticals(element, this.devConsultings);
          } else if (element.acf.category === "C4D") {
            this.handleVerticals(element, this.c4ds);
          } else if (element.acf.category === "Art For Life") {
            this.handleVerticals(element, this.artForLifes);
          } else if (element.acf.category === "MusiCal") {
            this.handleVerticals(element, this.musiCals);
          } else if (element.acf.category === "TourEast") {
            this.handleVerticals(element, this.tourEasts);
          }
        });
      }
    )
  }

  handleVerticals(inputData: any, department: any[]) {
    this.vertical = { wpId: inputData.id, title: inputData.acf.title, intro: inputData.acf.introduction, coverpictures: [inputData.acf.cover_picture_1, inputData.acf.cover_picture_2, inputData.acf.cover_picture_3, inputData.acf.cover_picture_4 ].filter((element) => element), content: inputData.acf.content, youtubes: [inputData.acf.youtube_video_link_1, inputData.acf.youtube_video_link_2, inputData.acf.youtube_video_link_3, inputData.acf.youtube_video_link_4 ].filter((element) => element)};
    department.push(this.vertical);
  }


  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    "nextArrow":"<div class='nav-btn next-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);'></div>",
    "dots":true,
    "infinite": false,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 3, slidesToScroll: 1, infinite: false, dots: true }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true
        }
      }, {
        breakpoint: 600, settings: { 
          slidesToShow: 1, slidesToScroll: 1 
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1 
        } 
      } 
    ] 
  };
  slickInit(e) {
  }
  breakpoint(e) {
  }
  afterChange(e) {
  }
  beforeChange(e) {
  }

}
