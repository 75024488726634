import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './routes/home/home.component';
import { WhoWeAreComponent } from './routes/who-we-are/who-we-are.component';
import { TestComponent } from './routes/test/test.component';
import { WhatWeDoComponent } from './routes/what-we-do/what-we-do.component';
import { WorkWithUsComponent } from './routes/work-with-us/work-with-us.component';
import { DocumentsComponent } from './routes/documents/documents.component';
import { MediaComponent } from './routes/media/media.component';
import { StoryDetailComponent } from './routes/story-detail/story-detail.component';
import { VerticalDetailComponent } from './routes/vertical-detail/vertical-detail.component';
import { WhatsNewDetailComponent } from './routes/whats-new-detail/whats-new-detail.component';
import { VerticalWithStoriesComponent } from './components/whatWeDoComps/vertical-with-stories/vertical-with-stories.component';
import { WhatsNewRouteComponent } from './routes/whats-new-route/whats-new-route.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'who-we-are', component: WhoWeAreComponent },
  { path: 'what-we-do',component: WhatWeDoComponent},
  { path: 'what-we-do/:vertical', component: VerticalWithStoriesComponent },
  { path: 'what-we-do/:vertical/:storyId', component: StoryDetailComponent },
  { path: 'work-with-us', component: WorkWithUsComponent},
  { path: 'documents', component: DocumentsComponent },
  { path: 'media', component: MediaComponent },
  { path: 'story/:id', component: StoryDetailComponent },
  { path: 'vertical/:id', component: VerticalDetailComponent },
  { path: 'whats-new/:id', component: WhatsNewDetailComponent },
  { path: 'test', component: TestComponent},
  { path: "whats-new", component: WhatsNewRouteComponent},
  { path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
