import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization/authorization.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterCategoryService {

  verticals: any[] = [];
  stories: any[] = [];

  theVerticals = new Subject<any>();

  constructor(
    private _authService: AuthorizationService
  ) { }

  filter() {
    this.verticals = [];
    this.stories = [];
    this._authService.getReq('categories?per_page=100').subscribe(
      (data: any[]) => {
        this.verticals = [];
        data.forEach(element => {
          if (element.parent === 0 && element.id!=1) {
            this.verticals.push({id: element.id, name: element.name, description: element.description});
            this.stories.push({parentId: element.id, stories: []});
          }
        });
        data.forEach((element) => {
          if (element.parent !== 0) {
            this.stories.forEach((story) => {
              if (story.parentId === element.parent) {
                story.stories.push({ name: element.name, description: element.description, id: element.id });
              }
            })
          }
        });
        this.theVerticals.next(this.verticals);
      }
    )
  }

}
